@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Syne:wght@400..800&display=swap');


:root{
  --Inter: 'Inter', sans-serif;
  --Inknut: "Inknut Antiqua", serif;
  --Syne: "Syne", sans-serif;
  /*COULEURS*/
  --bgcolor: #F2EFEA;
  --footerBg: rgba(242, 239, 234, 0.3);
  --lightOp: rgba(233, 197, 160, 0.54);
  --lightbg : #e9c5a09d;
  --noir: #000000;
  --titres:#355460;
  --corpstexte: black;
  --brown-text: #522D24;
  --non-active: #9c7a72;
  --sous-tireD: 40px; ///500 + inknut
  --sous-titreM: 15px; //500 + inknut
  --corpsdetexteD: 16px; // 400 inknut
  --corpsdetexteM: 12px;// 400 inknut
  --titreMenuD:  30px; // 700 inknut
  --titreMenuM: 15px; // 700 inknut
  --corpsdetextemenuD: 16px; // 700 inknut
  --corpsdetextemenuM: 12px; // 700 inknut
}
a{
  text-decoration: none;
}
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}
*{
  box-sizing : border-box;
  margin:0;
  padding: 0;
 & img{
  pointer-events: none;
 }
}
body{
  background-color: #F2EFEA;
}

.scrollable-container{
  // position: relative;
  overflow: hidden;
}
.section{
  padding: 0 2rem 0 2rem;
  margin-bottom: 2rem;
  position: relative;
  // margin-top: 5rem;
}

  
h1{
  font-family: var(--Syne);
  font-weight: 600;
  font-size: 80px;
}
h2{ //titres (autre que tout premier)
  font-family: var(--Syne);
  font-weight: 600;
  font-size: 60px;
  color: var(--titres);
}
h3{ // soustitre
  font-family: var(--Syne);font-weight: 500;
  font-size: var(--sous-tireD);
  color: var(--titres);

}
p{
  font-family: var(--Syne);font-weight: 300;
  font-size: var(--corpsdetexteD);
  color: var(--corpstexte);
  line-height: 1.5;
}


/* HEADER */
.header-container{
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
      top: 0; /* Le place en haut de la page */
      left: 0; /* Aligne à gauche */
      width: 100%;
  z-index: 1000;
  padding: 1rem;
  background-color: var(--bgcolor);
  & img:first-child{
    width: 7rem;
    height: fit-content;
  }
  & img:last-child{
    width: 35px;
  }
  & a{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
#citation{
  font-size: 8rem;
}
/* INTRO - GRILLE */
#titre-logo{
  z-index: 1;
  width: 1000px;
  height: auto;
}
#bg-intro{
  width: 500px;
  height: 100%;
  object-fit: cover;
  border-top-right-radius:390px;
  // position: absolute;
  // right: 40px;  
  z-index: -1;
}

/* CITATION */
.main{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 90vh;
}
.typewriter{
  font-family: var(--Syne);
  font-weight: 600;
  color: var(--titres);
  font-size: var(--corpsdetexteD);
}

///////// DISCIPLINE /////////
#grid3{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
}
.grid3-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
  & img{
     width: 130px;
     height: 130px;
   }
  & p {
    // width: 300px;
    line-height: calc(var(--corpsdetextemenuD)*1.5);
    text-align: center;
    font-size: var(--corpsdetextemenuD)!important;
  }
}
///////// VIDEO //////////////
/// 
.background-video {
  width: 100%;
  height: auto;
  object-fit: cover;
  & video{
    width: 100%;
    // height: 100%;
    & source{
      width: 100%;
      height: 100%;
    }
  }
}

.video{
  width: 100vw;
  height : 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}
.video video{
  width: 100%;
  height: 100%;
  object-fit: cover;
  & source{
    display: flex;
    width: 100%;
    height: 100%;
    
  }
}

///////// FONCTIONNEMENT //////////////
.carouselContainer{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: .5rem;
  padding: 2rem;
}
.carouselText{
  display: flex;
  flex-direction: column;
  background-color: var(--lightbg);
  border-radius: 20px;
  padding: 2rem;
  align-items: center;  
  text-align: center;
  justify-content: center;
  gap:2rem;
}
#carouselText-p{
  font-weight: 700;
  margin-top: 2rem;
  color: var(--brown-text);
}
.carouselImg {
  border-radius: 20px;
  & img{
    border-radius: 20px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
#separator{
  display: flex;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;

}
#separator img{
  width: 90px;
  margin : 0 1rem 0 1rem;
}
#tiret{
  border-top: 3px solid var(--brown-text);
  border-radius: 10px; /* Rayon des coins arrondis */
  height: auto;
  align-items: center;
  width: 10rem;
}


/* BIO */
.grid1-row2{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 2rem;
}
.grid1-row2-item1{
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.grid1-row2-item2{
  grid-area: 2 / 1 / 3 / 2;
  text-align: start;
}
#bio{
  align-items: flex-start;
}
.portrait{
    display: grid;
    grid-template-columns: 1fr;
    width:100%;
    margin-left: 5rem;
    & img{
      width: 100%;
    }
}
#menu{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  // padding: 3rem;
  text-align: center;
}
.menu-item{
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: .9rem;
}
#date{
  background-color: var(--lightOp);
  border-radius: 100%;
  width: fit-content;
  padding: 2rem;
  font-family: var(--Syne);
  font-weight: 600;
  font-style: var(--corpsdetextemenuD);
  color: var(--brown-text);
}
#date-diplome{
 font-size: calc(var(--corpsdetexteD) - 2pt);
}
#menu p:last-child{ //selectionne le dernier et l'avant dernier
  font-size: var(--corpsdetextemenuD);
  font-family: var(--Syne);
  color: var(--corpstexte);
  text-align: center;
}
#menu-carousel{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 2rem;
  margin-bottom: 3rem;
}
#menu-carousel p {
    font-family: var(--Syne);
    font-weight: 400;
    color: var(--corpstexte);
}
.flex-row-items{
 display: flex;
 flex-wrap: wrap;
 gap: 1rem;
 margin-top: 2rem;
 align-items: center;

}
.flex-row-item{
  display: flex;
  flex-direction: column; 
  gap: .5rem;
  align-items: center;
}
.flex-row-item img{
  width: 130px;
  height: 130px;
}
.flex-row-item p{
  width: 300px;
  line-height: 20px;
  text-align: center;
  font-size: var(--corpsdetextemenuD)!important;
}
#titreMenu{ // soustitre
  font-family: var(--Syne);font-weight: 500;
  font-size: var(--titreMenuD)!important;
  color: var(--titres);
}
.paragraphe{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0rem 1rem 0rem
}
#text-transition{
  font-size: calc(var(--sous-tireD)/1.2);
}
.text-transition-undefined{
  height: auto;
}

/* MAP */
#map{
  position: relative;
  display: grid;
  grid-template-columns: 5vw 3fr;
  grid-template-rows: 60vh;
  grid-column-gap: 10px;
  grid-row-gap: 0px;    
}
#map-lotus{
  position:absolute;
  left:-80px;
  z-index: 2;
  grid-area: 1 / 1 / 2 / 2;
}
.mapbox{
  width: 100vw;
  height: 100%;
  grid-area: 1 / 2 / 2 / 3;
  pointer-events: none;
}
.leaflet-bottom .leaflet-right{
  opacity: 0!important;
}
.leaflet-touch .leaflet-control-attribution{
  display: none;
}
.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 1;
}


/* FOOTER */
.footer{
  background-color: var(--lightbg);
  width: 100%;
  padding: 1rem;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5rem;
  padding: 2rem;
  }
.grid2-item{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: .5rem;
  color: black;
  font-size: 1rem;
  font-family: var(--Titles);
  height: 100%;
}
.grid2-item-right{
  display: flex;
  justify-content: end;
}
.grid2-item-right img{
  width: 15rem;
  // height: fit-content;
}
#cp{
  font-size: .75rem;
}


/* Coordonnées Roue*/
.Conteneur{
  position:absolute;
  left:90vw!important;
  width:9vw!important;
  background-color: white;
  border-radius: 100%;
  padding: .3rem;
  z-index: 2000!important;
}
.logo-roue {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1000px;
  width: 3rem;
}
.logo-roue img{
  width: 3rem;
}
text {
  font-size: .8rem;
}
.Conteneur svg{
  z-index: 1000;
  color: white;
}
.rotate-svg {
  font-family: var(--font-primary);
  animation: rotate 8s linear infinite;
}
text{
  color: white;
}
@keyframes rotate {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}


//////// CONTACT //////////////
.grid-col3  {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; 
  position: relative;
}
.logo{
  width: 2rem;
  height: auto;
}

#intro-p{
  font-size: 22px;
}

/* MEDIA QUERIES*/
@media only screen and (max-width: 1440px){
  .grid2:first-of-type{
    padding: 0;
    grid-column-gap: 0;
  }
  #bg-intro{
    width: 320px;
    height: 100%;
    object-fit: cover;
    border-top-right-radius:390px;
    z-index: -1;
    margin-left: -10px;
  }
}
@media only screen and (max-width: 1024px) {
  h1{
    font-size: 50pt;
  }
  h2{
    font-size: 68pt;
  }
  h3{
    font-size: 33pt;
  }
  #titre-logo{
    width: 600px;
  }
  #flex-col-bio{
    margin-left: 1rem;
  }
  .Conteneur{
    width:10.5vw!important;
    height: auto!important;
    padding: .3rem;
    left: 92vw!important;
  }
  #grid3{
    display: grid;
    grid-template-columns: 1fr;
  }
}
/* BREAKPOINTS 768PX */ 
@media screen and (max-width: 768px) {
h1{
    font-size: 40pt;
}
h2{
    font-size: 30pt;
}
h3{
    font-size: 20px!important;
}
#citation{
  font-size: 5rem;
}
#titreMenu{ // soustitre
    font-family: var(--Syne);font-weight: 300;
    color: var(var(--corpstexte))
}
p{
    font-size: var(--corpsdetexteM);
}
#intro-p{
  font-size: 17px;
}
.body-type p{
    font-size: 70pt;
    margin: 0px;
    margin-right: 1.5vw;
}
.body-type span{
 opacity: 0.1;
}
.flex-row-items{
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
}
.flex-row-item{
 display: flex;
 flex-direction: column; 
 gap: .5rem;
 align-items: center;
}
.flex-row-item img{
  width: 80px;
  height: 80px;
}
.flex-row-item p{
  width: 320px;
  text-align: center;
  font-size: var(--corpsdetexteM)!important;
}
.paragraphe{
  text-align: center;
  font-size: var(--corpsdetexteM);
}
.main{
  height: 50vh;
}
#contact{
  padding: .8rem;
  font-size: var(--corpsdetextemenuM);
}
#date{
  border-radius: 100%;
  width: fit-content!important ;
  font-style: var(--corpsdetextemenuM);
}
#date-diplome{
  margin-top: 1rem;
  font-size: calc(var(--corpsdetexteM) + 2pt);
  text-align: center;
}
#text-transition{
  font-size: calc(var(--sous-titreM)/1.5);
}        
.header-container{
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
      top: 0; /* Le place en haut de la page */
      left: 0; /* Aligne à gauche */
      width: 100%;
  z-index: 6000;
  padding: 1rem;
    & img:first-child{
    width: 5rem;
    }
    & img:last-child{
    width: 1rem;
    }
}
.display{
  display: none;
}
#titre-logo{
  width: 100%;
}
#menu-carousel p {
    font-size: var(--corpsdetexteM);
}
#separator img{
    width: 30px;
}
#tiret{
  border-top: 2px solid var(--corpstexte);
  border-radius: 10px; /* Rayon des coins arrondis */
  height: auto;
  align-items: center;
  width: 90px;
}
.carouselContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: auto;
  grid-row-gap: 10px;

}
.carouselText{
  width: 90vw!important;
  height: fit-content;
  gap:.5rem!important;
}
.carouselImg{
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  width: 90vw!important;
  height: 70vh;
  justify-content: center;
  align-items: center;  
  text-align: center;
  gap:2rem;
}
#menu-carousel{
 margin-bottom: 1rem!important;
}
.Conteneur{
  width:20vw!important;
  padding: .3rem;
  left: 80vw!important;
}
.logo-roue {
  width: 2.5rem!important;
}
.logo-roue img{
  width: 2.5rem!important;
}
.grid2 {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  padding: 0;
}
.grid1-row2 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}
.grid1-row2-item1{
  justify-content: center;
  text-align: center;
    grid-area: 1 / 1 / 2 / 2;
}
.portrait{
  width: 100%!important;
  display: flex;
  justify-content: center;
  margin: 0;
}
.portrait img{
  width: 70%;
}
#textBio{
  padding: 1rem;
}
#grid2-justify{
  justify-content: center;
  align-items: center;
  text-align: center;
}
#grid2-item-justify{
  justify-content: center;
  padding: 0 3rem 0 3rem;
}
#bg-intro-justify{
  justify-content: center;
  align-items: center;
  & img{
    margin-top: 2rem;
    width: 450px;
    height: auto;
  }
}
#bio{
  justify-content: center;
  align-items: center;
}
}
/*BREAKPOINTS 425PX*/
@media screen and (max-width: 425px) {
  h2{
  font-size: 37pt;
  }
  h3{
  font-size: calc(var(--sous-titreM) - 5pt);
  }
  .titreMenu{ // soustitre
  font-size: calc(var(--titreMenuM) + 5pt);
  font-family: var(--Syne);
  font-weight: 300;
  color: var(var(--corpstexte))
  }
  p{
  font-size: var(--corpsdetexteM);
  width: 80vw;
  }
  #menu-carousel{
    gap: .5rem;
    width: 100%;
  }
  #citation{
    font-size: 3rem;
  }
  #contact{
    width: 96% !important;
    margin: .5rem;
  }
  .carouselText{
    gap: .5rem;
    padding-top: .1rem;
  }
  #carouselText-p{
    font-size: 30pt;
  }
  #menu-carousel p {
      font-size: var(--corpsdetexteM);
  }
  #separator img{
    width: 30px;
  }
  #tiret{
    border-top: 2px solid var(--brown-text);
    border-radius: 10px; /* Rayon des coins arrondis */
    height: auto;
    align-items: center;
    width: 35%;
  }
  #date{
    width: fit-content;
  }
  .portrait img{
    width: 80vw!important;
  }
  .section{
    margin: 0;
    // margin-top: 3rem;
    padding: 0;
  }
  .flex-row-items{
    justify-content: center;
   }
  .flex-row-item{
     display: flex;
     flex-direction: column; 
     gap: .5rem;
     align-items: center;
  }
  .flex-row-item:last-of-type{
    margin-bottom: 1.5rem;
  }
   .flex-row-item img{
     width: 60px;
     height: auto;
   }
   .flex-row-item p:not(.titreMenu){
     width: 300px;
     text-align: center;
     font-size: calc(var(--corpsdetextemenuM) - 2pt)!important;
   }
    .paragraphe{
    text-align: center;
    width: 600px;
    font-size: var(--corpsdetexteM);
    margin-top: 2rem;
    }
    #contact{
      padding: .8rem;
      font-size: var(--corpsdetextemenuM);
    }
    #menu-p{
      width: 95%;
    }
    #date{
      border-radius: 100%;
      width: fit-content!important ;
      font-style: var(--corpsdetextemenuM);
    }
    #date-diplome{
      margin-top: 1rem;
      font-size: calc(var(--corpsdetexteM) + 2pt);
    }
    .portrait{
      top : 10px !important;
    }
    .portrait img{
      width: 60vw!important
    }
.body-type p{
        font-size: 47pt;
        margin-right: 3vw;
}
.Conteneur{
        padding: .3rem;
        left: 84vw!important;
}
.logo-roue {
        width: 2rem!important;
}
.logo-roue img{
        width: 2rem!important;
}
#body-type-p{
        width: fit-content;
}
.video{
        width: 100vw;
        height : 70vh;
        overflow-x: hidden;
        overflow-y: hidden;
}
#intro-p{
        width: 100%;
}
}
/*BREAKPOINTS 375PX*/
@media screen and (max-width: 375px) {
  .grid-col2 h1{
    font-size: 30pt;
  }
  .grid-col2 p{
    margin-left: 0;
  }
  .flex-row-item p:not(.titreMenu){
    width: 150px;
  }
  .flex-col h2{
    margin-left: 0;
    text-align: center;
  }
  h2{
    font-size: 28pt;
  }
  #carouselText-p{
    font-size: 22pt;
  }
  .body-type p{
    font-size: 30pt;
  }
}
@media screen and (max-width: 320px) {
  #text-transition{
    font-size: calc(var(--sous-titreM)/2.5);
  }
  #grid2-item-justify {
    padding: 0 5rem 0 5rem;
  }
  .grid2-item h2{
    margin-top: -30px!important;
  }
}